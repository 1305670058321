import { paths } from "@app/paths";

import AppLayout from "@templates/Layout/AppLayout/AppLayout";
// import Media from "./sections/Media/Media";
import Hero from "./sections/Hero/Hero";

import FugaziOFWallstreet from "./sections/FugaziOfWallstreet/FugaziOfWallstreet";
import Battle from "./sections/Battle/Battle";
import HowToPlay from "./sections/HowToPlay/HowToPlay";
import CountDown from "./sections/CountDown/CountDown";
import ExtraPowers from "./sections/ExtraPowers/ExtraPowers";

import "./Main.scss";
import Tokenomics from "./sections/Tokenomics/Tokenomics";
import { MenuController } from "@templates/Layout/Navbar/context/MenuContext";

const Main = () => {
  return (
    <AppLayout>
      <Hero />
      <CountDown />
      <FugaziOFWallstreet />
      <Battle />
      <HowToPlay />
      <ExtraPowers />
      <Tokenomics />
    </AppLayout>
  );
};

export default {
  path: paths.main,
  element: (
    <MenuController>
      <Main />
    </MenuController>
  ),
  children: []
};
