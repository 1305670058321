import { DEFAULT_CONTRACT_ADDRESS } from "@constants/constants";

export type AppStage = "development" | "production";

export const APP_STAGE: AppStage = (process.env.REACT_APP_STAGE as AppStage) || "development";
export const TARGET_TIMER_TIMESTAMP = (1720976400 || Number(process.env.REACT_APP_TARGET_TIMER_TIMESTAMP)) * 1000 || 0;
export const TOKEN_ADDRESS = process.env.REACT_APP_SMART_CONTRACT_ADDRESS || DEFAULT_CONTRACT_ADDRESS;
export const TOKEN_EXPLORER_URL = `https://basescan.org/address/${TOKEN_ADDRESS}`;

export const REACT_APP_UNISWAP_URL =
  process.env.REACT_APP_UNISWAP_URL ||
  "https://app.uniswap.org/swap?exactField=input&inputCurrency=ETH&outputCurrency=FGZ";
