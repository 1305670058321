import { Props } from "@interfaces/Props";
import React, { useEffect } from "react";
import useMenu from "./useMenu";
import useIsMobile from "@hooks/useIsMobile";

interface IMenuContext {
  close(): void;
  isOpened: boolean;
  open(): void;
  toggle(): void;
  isMobile: boolean;
}

const MenuContext = React.createContext(null as any);

export const MenuController = ({ children }: Props) => {
  const { close, isOpened, open, toggle } = useMenu();
  const isMobile = useIsMobile("lg");

  useEffect(() => {
    if (isMobile) return;

    close();
  }, [isMobile]);

  return <MenuContext.Provider value={{ close, isOpened, open, toggle, isMobile }}>{children}</MenuContext.Provider>;
};

export const useMenuControllerCtx = (): IMenuContext => React.useContext(MenuContext);
