import { CSSProperties, ReactNode, forwardRef } from "react";
import { BaseProps } from "@interfaces/BaseProps";
import "./Stack.scss";

export interface IStack extends BaseProps {
  children: ReactNode;
  colGap?: number;
  rowGap?: number;
  fullSize?: boolean;
  fullWidth?: boolean;
  fullHeight?: boolean;
  justifyContent?: CSSProperties["justifyContent"];
  alignItems?: CSSProperties["alignItems"];
  testId?: string;
  byPass?: boolean;
}

const Stack = forwardRef(
  (
    {
      rowGap = 0,
      colGap = 0,
      fullSize = false,
      fullWidth = false,
      fullHeight = false,
      children,
      justifyContent = "flex-start",
      alignItems = "flex-start",
      testId = "",
      byPass,
      ...rest
    }: IStack,
    ref: any
  ) => {
    if (byPass) return <>{children}</>;

    const classes = `stack${rest.className ? ` ${rest.className}` : ""}${fullSize ? " full-size" : ""}${
      fullWidth ? " full-width" : ""
    }${fullHeight ? " full-height" : ""}`;

    const isGap = !!rowGap || !!colGap;

    return (
      <div
        {...rest}
        ref={ref}
        className={`${classes}`}
        data-testid={testId}
        style={{
          gap: isGap ? `${rowGap}px ${colGap}px` : undefined,
          justifyContent,
          alignItems,
          ...rest.style
        }}
      >
        {children}
      </div>
    );
  }
);

export default Stack;
