import { BaseProps } from "@interfaces/BaseProps";

import "./FullSizeWrapper.scss";

interface Props extends BaseProps {
  background?: string;
}

const FullSizeWrapper = ({ children, background, ...rest }: Props) => {
  return (
    <section style={{ background }} {...rest} className={`full-size-wrapper ${rest.className}`}>
      {children}
    </section>
  );
};

export default FullSizeWrapper;
