import { ReactNode } from "react";
import Stack from "@design-system/Stack/Stack";

import "./Card.scss";

interface Props {
  img: string;
  title: string;
  description: string;
  button: ReactNode;
  isMobile: boolean;
}

const Card = ({ button, description, img, title, isMobile }: Props) => {
  return (
    <div className={`fugazi-card ${isMobile ? "mobile" : ""}`}>
      <Stack rowGap={30} fullSize>
        <img className="img" src={img} />
        <Stack fullWidth rowGap={8}>
          <h5 className="bold title">{title}</h5>
          <p className="p2 description">{description}</p>
        </Stack>
        {button}
      </Stack>
    </div>
  );
};

export default Card;
