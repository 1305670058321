import Stack from "@design-system/Stack/Stack";

import "./TimePart.scss";

interface TimePartProps {
  timePart: string;
  partOfTime: string;
}

const TimePart = ({ timePart, partOfTime }: TimePartProps) => {
  return (
    <Stack className="time-part" justifyContent="center" alignItems="center">
      <h1 className="blue-8 black-bold">{timePart}</h1>
      <p className="part-of-time-name p1">{partOfTime}</p>
    </Stack>
  );
};

export default TimePart;
