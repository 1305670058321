import { ParallaxProvider, useParallax } from "react-scroll-parallax";
import LimitedSizeWrapper from "@templates/LimitedSizeWrapper/LimitedSizeWrapper";
import FullSizeWrapper from "@templates/FullSizeWrapper/FullSizeWrapper";

import { sectionIds } from "@constants/sectionIds";
import { REACT_APP_UNISWAP_URL } from "src/config";

import Stack from "@design-system/Stack/Stack";
import useIsMobile from "@hooks/useIsMobile";

import FugaziButton from "src/components/Button/FugaziButton";
import Group from "@design-system/Group/Group";
import Button from "@design-system/Button/Button";
import Flex from "@design-system/Flex/Flex";
import Ribbon from "src/components/Ribbon/Ribbon";

import "./Hero.scss";

const DollarsParallax = () => {
  const { ref: ref1 } = useParallax({ speed: 40 });
  const { ref: ref2 } = useParallax({ speed: -20 });
  const { ref: ref3 } = useParallax({ speed: -40 });

  return (
    <div className="parallax-wrapper">
      <img
        ref={ref1 as any}
        className="plan-1 plan"
        src={`/images/hero/plan-1.png`}
        alt="parallax layer"
        loading="lazy"
        // width={1440}
        height={1577}
      />
      <img
        ref={ref2 as any}
        className="plan-2 plan"
        src={`/images/hero/plan-2.png`}
        alt="parallax layer"
        loading="lazy"
        // width={1440}
        height={1577}
      />
      <img
        ref={ref3 as any}
        className="plan-3 plan"
        src={`/images/hero/plan-3.png`}
        alt="parallax layer"
        loading="lazy"
        // width={1440}
        height={1577}
      />
    </div>
  );
};

const Hero = () => {
  const isMobile = useIsMobile("md");
  return (
    <Stack className="hero" alignItems="center" id={sectionIds.hero}>
      <ParallaxProvider>
        <DollarsParallax />
      </ParallaxProvider>
      <FullSizeWrapper>
        <LimitedSizeWrapper className="d-xs-f flex-xs-c col">
          <img
            src={`/images/hero/hero-logo${isMobile ? "-mobile.svg" : ".png"}`}
            className="relative"
            style={{
              zIndex: "11",
              marginBottom: isMobile ? "-60px" : "-265px",
              marginTop: isMobile ? "0px" : "-50px"
            }}
          />
          <h1
            className={`${isMobile ? "h3" : "h1"} text-shadow-${
              isMobile ? "3" : "5"
            } t-xs-c relative extra-bold mb-xs-3`}
            style={{ zIndex: "10", maxWidth: "1120px" }}
          >
            Gear up for the Meme war with FugaziAi
          </h1>
          <h4
            className={`${isMobile ? "h5" : "h4"} pt-xs-2 pb-xs-2 pl-xs-3 pr-xs-3 white-0 mb-xs-6 t-xs-c semi-bold`}
            style={{ background: "rgba(0, 0, 0, 0.4)", borderRadius: "16px", zIndex: 11, backdropFilter: "blur(10px)" }}
          >
            Connect your wallet, upload your Meme and get ready to big win.
          </h4>
          <Flex
            colGap={16}
            rowGap={16}
            style={{ zIndex: 12 }}
            baseRole={"group"}
            mobileBreakpoint={"lg"}
            fullWidth
            justifyContent="center"
            alignItems="center"
          >
            <Button as="button" onClick={() => {}} disabled={true} className="w-xs-100 w-lg-mc">
              <FugaziButton
                isDisabled={true}
                colorVariant={"green"}
                hierarchyVariant={"primary"}
                otherChildren={<Ribbon color={"success"} text={"Soon"} />}
              >
                <Group colGap={8} justifyContent="center" alignItems="center" fullSize>
                  <img src="/images/icons/metamask-head.svg" />
                  <p className="p1 uppercase white-black-13 semi-bold">connect metamask</p>
                </Group>
              </FugaziButton>
            </Button>
            <Button
              as="button"
              onClick={() => window.open(REACT_APP_UNISWAP_URL, "_blank")}
              disabled={false}
              className="w-xs-100 w-lg-mc"
            >
              <FugaziButton
                isDisabled={false}
                colorVariant={"uniswap"}
                hierarchyVariant={"primary"}
                style={{ width: "100%", minWidth: "unset" }}
              >
                <Group colGap={8} justifyContent="center" alignItems="center" fullSize>
                  <img src="/images/icons/uniswap-head.svg" />
                  <p className="p1 uppercase white-black-13 semi-bold">buy token</p>
                </Group>
              </FugaziButton>
            </Button>
          </Flex>
          {/* <img
            src="/images/graphics/hero-top.png"
            style={{ maxWidth: "100%", zIndex: "10" }}
            className="d-xs-n d-lg-b"
          />
          <img
            src="/images/graphics/hero-top-mobile.png"
            style={{ maxWidth: "100%", zIndex: "10" }}
            className="d-xs-b d-lg-n"
          /> */}
          <video
            controls
            poster={`/images/hero/video-placeholder${isMobile ? "-mobile" : ""}.jpg`}
            autoPlay
            muted
            playsInline
            style={{ marginTop: "60px", maxWidth: "1140px", width: "100%", borderRadius: "20px", zIndex: "10" }}
          >
            <source src={`/videos/fugazi-fight${isMobile ? "-mobile" : ""}.mp4`} type="video/mp4" />
          </video>
        </LimitedSizeWrapper>
      </FullSizeWrapper>
    </Stack>
  );
};

export default Hero;
