import { ReactNode } from "react";

import { BaseProps } from "@interfaces/BaseProps";

import "./FugaziButton.scss";

export type ColorVariants = "green" | "uniswap" | "secondary" | "tertiary" | "white";

export type HierarchyVariant = "primary" | "secondary" | "tertiary";

interface Props extends BaseProps<HTMLDivElement> {
  colorVariant: ColorVariants;
  hierarchyVariant: HierarchyVariant;
  children: ReactNode;
  isDisabled: boolean;
  otherChildren?: ReactNode;
}

const FugaziButton = ({ colorVariant, children, hierarchyVariant, isDisabled, otherChildren, ...rest }: Props) => {
  const { style, className, ...restRest } = rest;

  return (
    <div
      style={{ ...style, borderRadius: "8px", overflow: "hidden", position: "relative" }}
      className={`wrap ${className}`}
    >
      <div
        {...restRest}
        className={`fugazi-button color-${colorVariant} hierarchy-${hierarchyVariant} ${isDisabled ? "disabled" : ""}`}
      >
        {children}
      </div>
      {otherChildren}
    </div>
  );
};

export default FugaziButton;
