import { hexColors } from "@constants/hexColors";
import FullSizeWrapper from "@templates/FullSizeWrapper/FullSizeWrapper";
import LimitedSizeWrapper from "@templates/LimitedSizeWrapper/LimitedSizeWrapper";
import Timer from "src/components/Timer/Timer";

import "./CountDown.scss";

const CountDown = () => {
  return (
    <FullSizeWrapper background={hexColors.yellowish} className="count-down">
      <LimitedSizeWrapper>
        <div className="pt-xs-17 pb-xs-17">
          <h2
            className="t-xs-c m-xs-a mb-xs-8 text-shadow-5 extra-bold"
            style={{ maxWidth: "900px", color: "#041537" }}
          >
            The countdown for the MemeStreet is on
          </h2>
          <Timer />
        </div>
      </LimitedSizeWrapper>
    </FullSizeWrapper>
  );
};

export default CountDown;
