import FullSizeWrapper from "@templates/FullSizeWrapper/FullSizeWrapper";
import LimitedSizeWrapper from "@templates/LimitedSizeWrapper/LimitedSizeWrapper";

import Stack from "@design-system/Stack/Stack";
import FugaziButton from "src/components/Button/FugaziButton";
import Group from "@design-system/Group/Group";

import { hexColors } from "@constants/hexColors";
import { sectionIds } from "@constants/sectionIds";

import Button from "@design-system/Button/Button";
import Ribbon from "src/components/Ribbon/Ribbon";

import "./Battle.scss";

const Battle = () => {
  return (
    <FullSizeWrapper background={hexColors.yellowish} id={sectionIds.battle} className="overflow-x-n">
      <LimitedSizeWrapper className="battle-bg">
        <Stack className="battle" alignItems="center" fullSize>
          <h3 className="mb-xs-4 text-shadow-5 semi-bold t-xs-c d-xs-n d-lg-b blue-14" style={{ maxWidth: "1000px" }}>
            Connect your wallet and Choose your preferred meme through the epic lineup
          </h3>
          <h2 className="d-xs-b d-lg-n t-xs-c blue-14 mb-xs-3 text-shadow-5 bold" style={{ maxWidth: "350px" }}>
            Connect your wallet
          </h2>
          <h4 className="t-xs-c blue-14 text-shadow-5 mb-xs-3 d-xs-b d-lg-n semi-bold" style={{ maxWidth: "350px" }}>
            and choose your preferred meme through the epic lineup
          </h4>
          <Button as="button" disabled={true} className="w-xs-100 w-lg-mc">
            <FugaziButton
              onClick={() => console.log("connect metamask")}
              colorVariant={"green"}
              hierarchyVariant={"primary"}
              isDisabled={true}
              className="mb-xs-4"
              otherChildren={<Ribbon color="success" text="soon" />}
            >
              <Group fullSize justifyContent="center" alignItems="center" colGap={8}>
                <img src="/images/icons/metamask-head.png" />
                <p className="p1 uppercase semi-bold">connect metamask</p>
              </Group>
            </FugaziButton>
          </Button>
          <Group fullWidth justifyContent="center" className="relative d-xs-n d-lg-f">
            <img src="/images/graphics/battle-left.png" />
            <img
              src="/images/icons/vs.png"
              style={{
                position: "absolute",
                left: "50%",
                top: "50%",
                transform: "translate(-50%, calc(-50% - 40px))"
              }}
            />
            <img src="/images/graphics/battle-right.png" />
          </Group>
          <Group fullWidth className="d-xs-f d-lg-n" justifyContent="center" style={{ marginBottom: "-50px" }}>
            <img src="/images/graphics/battle-teams-mobile.png" />
          </Group>
        </Stack>
      </LimitedSizeWrapper>
    </FullSizeWrapper>
  );
};

export default Battle;
