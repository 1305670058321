import { GlobalSocialsId } from "@interfaces/GlobalSocialsId";

export const globalSocials: Record<GlobalSocialsId, { url: string }> = {
  youtube: {
    url: "https://www.youtube.com/@FugaziAi"
  },
  telegram: {
    url: "https://t.me/fugaziai"
  },
  x: {
    url: "https://twitter.com/FugaziAi"
  }
};
