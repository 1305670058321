import { HexColors, hexColors } from "@constants/hexColors";

import "./Ribbon.scss";

interface Props {
  color: HexColors;
  text: string;
}

const Ribbon = ({ color, text }: Props) => {
  return (
    <div className="ribbon">
      <div className="content" style={{ background: hexColors[color] }}>
        <p className="p1 blue-1 uppercase">{text}</p>
      </div>
    </div>
  );
};

export default Ribbon;
