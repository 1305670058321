// import { Fragment } from "react";
import FullSizeWrapper from "@templates/FullSizeWrapper/FullSizeWrapper";
import LimitedSizeWrapper from "@templates/LimitedSizeWrapper/LimitedSizeWrapper";

// import Stack from "@design-system/Stack/Stack";
// import Group from "@design-system/Group/Group";

import { hexColors } from "@constants/hexColors";
import useIsMobile from "@hooks/useIsMobile";

import "./FugaziOfWallstreet.scss";

// const media = ["upwork", "petal", "rakuten", "nyt", "vice", "dell"];

const FugaziOFWallstreet = () => {
  const isMobile = useIsMobile("md");
  return (
    <FullSizeWrapper background={hexColors.yellowish} className="overflow-x-n">
      <LimitedSizeWrapper>
        <div className="fugazi-of-wallstreet">
          {/* <h2 className="t-xs-c m-xs-a text-shadow-5 semi-bold">Be crypto wolf of FugaziAi</h2> */}
          <img
            src={`/images/graphics/fugazi-of-wallstreet${isMobile ? "-mobile" : ""}.jpg`}
            className={`${isMobile ? "mobile" : ""}`}
            width={"100%"}
          />
        </div>
      </LimitedSizeWrapper>
    </FullSizeWrapper>
  );
};

export default FugaziOFWallstreet;
