import { ReactNode, useEffect } from "react";
import useBoolean from "@hooks/useBoolean";

import "./MobileMenu.scss";
import useLockScroll from "@hooks/useLockScroll";

interface Props {
  isOpened: boolean;
  content: ReactNode;
}

const MobileMenu = ({ content, isOpened }: Props) => {
  const [activeAnimations, show] = useBoolean(false);

  // useLockScroll({ isActive: isOpened });

  useEffect(() => {
    if (isOpened) show();
  }, [isOpened]);

  return <div className={`menu ${activeAnimations ? "animations" : ""} ${isOpened ? "show" : "hide"}`}>{content}</div>;
};

export default MobileMenu;
