import FullSizeWrapper from "@templates/FullSizeWrapper/FullSizeWrapper";
import LimitedSizeWrapper from "@templates/LimitedSizeWrapper/LimitedSizeWrapper";
import Group from "@design-system/Group/Group";

import { hexColors } from "@constants/hexColors";
import Card from "./Card/Card";
import StepsButton from "./Button/StepsButton";

import { sectionIds } from "@constants/sectionIds";
import { Fragment, ReactNode } from "react";
import useIsMobile from "@hooks/useIsMobile";
import Flex from "@design-system/Flex/Flex";

import "./HowToPlay.scss";

interface ICard {
  img: string;
  title: string;
  description: string;
  button: ReactNode;
}

const cards: ICard[] = [
  {
    img: "/images/graphics/how-to-start/1.png",
    title: "Download FugaziAi LogoPack",
    description: "Download FugaziAI Logo pack and showcase your meme-making skills",
    button: (
      <StepsButton onClick={() => console.log("download logo")} disabled>
        <Group justifyContent="center" colGap={8} alignItems="center">
          <img src="/images/icons/download.svg" />
          <p className="p1 bold uppercase white-0">Download logo</p>
        </Group>
      </StepsButton>
    )
  },
  {
    img: "/images/graphics/how-to-start/2.png",
    title: "Upload your FugaziAI meme to the tournament",
    description:
      "Drop Your FugaziAI meme into the ring, shell out the participation fee and let the meme battle commence",
    button: (
      <StepsButton onClick={() => console.log("upload logo")} disabled>
        <Group justifyContent="center" colGap={8} alignItems="center">
          <img src="/images/icons/upload.svg" />
          <p className="p1 bold uppercase white-0">Upload your meme</p>
        </Group>
      </StepsButton>
    )
  },
  {
    img: "/images/graphics/how-to-start/3.png",
    title: "Win the FugaziAI meme battle",
    description:
      "Cast your vote for the week's top meme – whether you're rolling in with a free vote or making it rain with a paid one! Snag a sweet 40% slice of the jackpot if you back the winning meme, but if you're the creative genius behind the champ, pocket a whopping 60%",
    button: (
      <StepsButton onClick={() => console.log("start battle")} disabled>
        <Group justifyContent="center" alignItems="center">
          <p className="p1 bold uppercase white-0">start the battle</p>
        </Group>
      </StepsButton>
    )
  }
];

const HowToPlay = () => {
  const isMobile = useIsMobile("xl");
  return (
    <FullSizeWrapper background={hexColors.blue5} id={sectionIds.howToPlay}>
      <LimitedSizeWrapper>
        <div className="how-to-start pt-xs-10 pb-xs-10">
          <h2 className="t-xs-c m-xs-a text-shadow-3 bold mb-xs-6">How to play</h2>
          <Flex
            justifyContent="center"
            alignItems="center"
            colGap={12}
            rowGap={18}
            fullSize
            baseRole={"group"}
            mobileBreakpoint={"xl"}
          >
            {cards.map((card, index) => {
              return (
                <Fragment key={index}>
                  <Card {...card} isMobile={isMobile} />
                  <img
                    src={`/images/icons/triangle-${isMobile ? "down" : "right"}.svg`}
                    className={index === cards.length - 1 ? "d-xs-n" : ""}
                  />
                </Fragment>
              );
            })}
          </Flex>
        </div>
      </LimitedSizeWrapper>
    </FullSizeWrapper>
  );
};

export default HowToPlay;
