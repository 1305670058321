const second = 1000;
const minute = 60;
const hour = 60;
const day = 24;

export const timePartAsString = (timePart: number) => {
  return ("0" + timePart).slice(-2);
};

export const getTimeLeft = (timestamp: number) => {
  const now = new Date().getTime();
  const timeLeft = timestamp - now;

  // Calculate days, hours, minutes, and seconds left
  const daysLeft = Math.floor(timeLeft / (1000 * 60 * 60 * 24));
  const hoursLeft = Math.floor((timeLeft % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
  const minutesLeft = Math.floor((timeLeft % (1000 * 60 * 60)) / (1000 * 60));
  const secondsLeft = Math.floor((timeLeft % (1000 * 60)) / 1000);

  return {
    days: daysLeft,
    hours: hoursLeft,
    minutes: minutesLeft,
    seconds: secondsLeft
  };
};

export const splitTimePart = (timePart: string) => {
  return [timePart[0], timePart[1]];
};
