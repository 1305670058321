import Stack from "@design-system/Stack/Stack";

import { Props } from "@interfaces/Props";
import Footer from "../Footer/Footer";
import Navbar from "../Navbar/Navbar";

import MobileMenu from "../Navbar/MobileMenu/MobileMenu";
import { useMenuControllerCtx } from "../Navbar/context/MenuContext";

import "./AppLayout.scss";
import MobileMenuContent from "../Navbar/MobileMenu/MobileMenuContent/MobileMenuContent";

const AppLayout = ({ children }: Props) => {
  const menu = useMenuControllerCtx();
  return (
    <div className="app-layout">
      <Navbar />
      <Stack fullWidth>{children}</Stack>
      <Footer />
      <MobileMenu isOpened={menu.isOpened} content={<MobileMenuContent />} />
    </div>
  );
};

export default AppLayout;
