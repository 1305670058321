import ReactDOM from "react-dom/client";
import Router from "./app/Router";
import { HelmetProvider } from "react-helmet-async";

const rootElement = document.getElementById("root");
const ReactRoot = ReactDOM.createRoot(rootElement!);

ReactRoot.render(
  <HelmetProvider>
    <Router />
  </HelmetProvider>
);
