// import FullSizeWrapper from "@templates/FullSizeWrapper/FullSizeWrapper";
import LimitedSizeWrapper from "@templates/LimitedSizeWrapper/LimitedSizeWrapper";

import Group from "@design-system/Group/Group";
// import Actions from "./components/Actions/Actions";

// import { MenuController } from "./context/MenuContext";
// import MenuList from "./components/MenuList/MenuList";

import useIsMobile from "@hooks/useIsMobile";
import Button from "@design-system/Button/Button";

import { scrollToId } from "@utils/scrollToId";
import { sectionIds } from "@constants/sectionIds";
import { globalSocials } from "@constants/globalSocials";
import { useMenuControllerCtx } from "./context/MenuContext";

import "./Navbar.scss";

const nav = [
  { text: "battle", sectionId: sectionIds.battle },
  { text: "how to play", sectionId: sectionIds.howToPlay },
  { text: "extra powers", sectionId: sectionIds.extraPowers },
  { text: "tokenomics", sectionId: sectionIds.tokenomics }
];
const socials = [
  { img: "x-white.svg", url: globalSocials.x.url },
  { img: "facebook-white.svg", url: "" },
  { img: "telegram-white.svg", url: globalSocials.telegram.url },
  { img: "youtube-white.svg", url: globalSocials.youtube.url }
];

const Navbar = () => {
  const menu = useMenuControllerCtx();

  return (
    <LimitedSizeWrapper style={{ zIndex: 100 }} className="relative">
      <div className={`navbar ${menu.isMobile ? "mobile" : ""}`}>
        <div className="nav-inner">
          <Group className="nav-content" justifyContent="space-between" fullSize>
            <Group colGap={32}>
              <img
                src={`/images/logos/main-logo${menu.isMobile ? "-mobile.svg" : ".png"}`}
                onClick={() => scrollToId(sectionIds.hero)}
                style={{ cursor: "pointer" }}
              />
              <Group colGap={32} className="d-xs-n d-lg-f">
                {nav.map((navEl, id) => (
                  <button key={id} onClick={() => scrollToId(navEl.sectionId)}>
                    <p className="p2 semi-bold uppercase white-0">{navEl.text}</p>
                  </button>
                ))}
              </Group>
            </Group>
            <Group colGap={32} className="d-xs-n d-lg-f">
              {socials
                .filter((social) => !!social.url && social.url !== "")
                .map((socialEl, id) => (
                  <a href={socialEl.url} target="_blank" key={id}>
                    <img src={`/images/socials/${socialEl.img}`} height={24} width={24} />
                  </a>
                ))}
            </Group>
            <Button as="button" onClick={menu.toggle} className="d-xs-b d-lg-n">
              <img src="/images/icons/hamburger.svg" />
            </Button>
          </Group>
        </div>
      </div>
    </LimitedSizeWrapper>
  );
};

export default Navbar;
