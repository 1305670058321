import { Outlet } from "react-router";
import { Helmet } from "react-helmet-async";

import Main from "./Routes/Main/Main";
import { paths } from "./paths";

import "@scss/consistency.scss";
import "@scss/layers.scss";
import "@scss/mixins.scss";
import "@scss/reset.scss";
import "@scss/responsive.scss";
import "@scss/typography.scss";
import "@scss/utils.scss";
import "@scss/variables.scss";

const App = () => {
  return (
    <>
      <Helmet htmlAttributes={{ lang: "en" }}></Helmet>
      <Outlet />
    </>
  );
};

export default {
  path: paths.main,
  element: <App />,
  children: [Main]
};
