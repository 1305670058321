import Stack from "@design-system/Stack/Stack";

import "./MobileMenuContent.scss";
import Group from "@design-system/Group/Group";
import Button from "@design-system/Button/Button";
import { LIGHT_PAPER_URL, LOGO_PACK_SRC, OPEN_SEA_URL } from "@constants/constants";
import { globalSocials } from "@constants/globalSocials";
import { TOKEN_EXPLORER_URL } from "src/config";
import { addTokenToMetamask } from "@utils/metamask";
import { fugaziToken } from "@constants/fugaziToken";

interface Props {
  title: string;
  logoSrc?: string;
  arrowSrc?: string;
}

const MenuComponent = ({ title, arrowSrc, logoSrc }: Props) => {
  return (
    <Group fullWidth colGap={16} justifyContent="flex-start" alignItems="center">
      {logoSrc && <img src={logoSrc} width={32} height={32} />}
      <Group fullWidth alignItems="center" justifyContent="flex-start" colGap={8}>
        <h4 className="blue-1 flex-1 border-bottom t-xs-l" style={{ fontSize: "25px" }}>
          {title}
        </h4>
        {arrowSrc && <img src={arrowSrc} />}
      </Group>
    </Group>
  );
};

const Header = ({ title }: { title: string }) => {
  return <p className="p1 white-0">{title}</p>;
};

const MobileMenuContent = () => {
  return (
    <Stack className="mobile-menu-content" fullSize justifyContent="flex-end" alignItems="center">
      <Stack fullWidth rowGap={16} className="pt-xs-2">
        <Button as="a" href={LOGO_PACK_SRC} download className="w-xs-100">
          <MenuComponent
            title="Download logo"
            arrowSrc="/images/icons/arrow-down.svg"
            logoSrc="/images/logos/logo-white-32.png"
          />
        </Button>
        <Stack fullWidth>
          <Header title="Socials" />
          <Button as="a" href={globalSocials.x.url} target="_blank" className="w-xs-100">
            <MenuComponent
              title={"Twitter"}
              logoSrc="/images/socials/x-white.svg"
              arrowSrc="/images/icons/arrow-top-right-white.svg"
            />
          </Button>

          <Button as="a" href={globalSocials.telegram.url} target="_blank" className="w-xs-100">
            <MenuComponent
              title={"Telegram"}
              logoSrc="/images/socials/telegram-white.svg"
              arrowSrc="/images/icons/arrow-top-right-white.svg"
            />
          </Button>
          <Button as="a" href={globalSocials.youtube.url} target="_blank" className="w-xs-100">
            <MenuComponent
              title={"Youtube"}
              logoSrc="/images/socials/youtube-white.svg"
              arrowSrc="/images/icons/arrow-top-right-white.svg"
            />
          </Button>
        </Stack>
        <Stack fullWidth>
          <Header title={"Documentation"} />
          <Button as="a" href={OPEN_SEA_URL} target="_blank" className="w-xs-100">
            <MenuComponent
              title={"Buy on Opensea"}
              arrowSrc="/images/icons/arrow-top-right-white.svg"
              logoSrc="/images/logos/open-sea-logo.svg"
            />
          </Button>
          <Button as="a" href={LIGHT_PAPER_URL} download className="w-xs-100">
            <MenuComponent title={"Light Paper"} arrowSrc="/images/icons/arrow-top-right-white.svg" />
          </Button>
        </Stack>
        <Stack fullWidth>
          <Header title="Tokenomics" />
          <Button as="a" href={TOKEN_EXPLORER_URL} target="_blank" className="w-xs-100">
            <MenuComponent
              title={"Basescan.org"}
              arrowSrc="/images/icons/arrow-top-right-white.svg"
              logoSrc="/images/logos/base-monochrome-32.svg"
            />
          </Button>
          <Button as="button" className="w-xs-100" onClick={() => addTokenToMetamask(fugaziToken)}>
            <MenuComponent
              title={"Add token"}
              arrowSrc="/images/icons/arrow-top-right-white.svg"
              logoSrc="/images/logos/metamask-head-32.svg"
            />
          </Button>
        </Stack>
      </Stack>
    </Stack>
  );
};

export default MobileMenuContent;
