import { useEffect, useState } from "react";

import TimePart from "./TimePart";
import { getTimeLeft, timePartAsString } from "@utils/timeHelper";
import Group from "@design-system/Group/Group";
import { TARGET_TIMER_TIMESTAMP } from "src/config";

import "./Timer.scss";

const Timer = () => {
  const [days, setDays] = useState<string | null>(null);
  const [hours, setHours] = useState<string | null>(null);
  const [minutes, setMinutes] = useState<string | null>(null);
  const [seconds, setSeconds] = useState<string | null>(null);

  useEffect(() => {
    const interval = setInterval(() => {
      const { days, hours, minutes, seconds } = getTimeLeft(TARGET_TIMER_TIMESTAMP);

      const now = Date.now();
      if (TARGET_TIMER_TIMESTAMP < now) {
        clearInterval(interval);
        return;
      }

      setDays(days.toString());
      setHours(hours.toString());
      setMinutes(minutes.toString());
      setSeconds(seconds.toString());
    }, 1000);
  }, []);

  return (
    <Group className="timer m-xs-a" justifyContent="space-between">
      <TimePart timePart={!!days ? timePartAsString(Number(days)) : "-"} partOfTime="days" />
      <TimePart timePart={!!hours ? timePartAsString(Number(hours)) : "-"} partOfTime="hours" />
      <TimePart timePart={!!minutes ? timePartAsString(Number(minutes)) : "-"} partOfTime="minutes" />
      <TimePart timePart={!!seconds ? timePartAsString(Number(seconds)) : "-"} partOfTime="seconds" />
    </Group>
  );
};

export default Timer;
