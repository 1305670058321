export const hexColors = {
  yellowish: "#FEF4E8",
  blue1: "#fdfdff",
  blue2: "#e8effe",
  blue3: "#d3e0fc",
  blue4: "#bed2fb",
  blue5: "#a9c4f9",
  blue6: "#7ea7f7",
  blue7: "#548bf4",
  blue8: "#2a6ef1",
  blue9: "#225ccc",
  blue10: "#1b4aa7",
  blue11: "#133981",
  blue12: "#0f306f",
  blue13: "#0c275c",
  blue14: "#041537",
  blue15: "#040b18",
  black0: "#000000",
  success: "#36BD2B"
};

export type HexColors = keyof typeof hexColors;
