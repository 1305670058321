import { hexColors } from "@constants/hexColors";
import Button from "@design-system/Button/Button";
import Stack from "@design-system/Stack/Stack";
import FullSizeWrapper from "@templates/FullSizeWrapper/FullSizeWrapper";
import LimitedSizeWrapper from "@templates/LimitedSizeWrapper/LimitedSizeWrapper";
import Group from "@design-system/Group/Group";

import useIsMobile from "@hooks/useIsMobile";
import Ribbon from "src/components/Ribbon/Ribbon";

import "./ExtraPowers.scss";
import { sectionIds } from "@constants/sectionIds";

const wolvesId = ["1", "2", "3", "4", "5"];

const ExtraPowers = () => {
  const isMobile = useIsMobile("lg");
  return (
    <FullSizeWrapper
      background={hexColors.blue7}
      className="open-sea pt-xs-11 pb-xs-15 pt-lg-16 pb-lg-16"
      id={sectionIds.extraPowers}
    >
      <LimitedSizeWrapper>
        <Stack alignItems="center" fullWidth>
          <h2
            className={`${isMobile ? "h4" : "h2"} text-shadow-2 t-xs-c extra-bold mb-xs-4`}
            style={{ maxWidth: "1000px" }}
          >
            Gain extra POWER with the Fugazi Street Pack NFT collection
          </h2>
          <Group className="relative overflow-n mb-xs-4" style={{ borderRadius: "8px", maxWidth: "100%" }}>
            <Button as="button" onClick={() => console.log("open sea")} className="op-06 none-events w-xs-100" disabled>
              <img src={`/images/icons/open-sea${isMobile ? "-mobile" : ""}.svg`} className="open-sea-btn w-xs-100" />
            </Button>
            <Ribbon color={"success"} text={"Soon"} />
          </Group>
          {/* <img src="/images/graphics/wolves.png" className="m-xs-a" /> */}
          <Group justifyContent="center" alignItems="center" fullWidth colGap={12} rowGap={12} className="flex-wrap">
            {wolvesId.map((wolfID) => (
              <img src={`/images/open-sea/${wolfID}.jpg`} key={wolfID} className="wolf" />
            ))}
          </Group>
        </Stack>
      </LimitedSizeWrapper>
    </FullSizeWrapper>
  );
};

export default ExtraPowers;
