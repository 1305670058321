// import "./Tokenomics.scss";
import FullSizeWrapper from "@templates/FullSizeWrapper/FullSizeWrapper";
import LimitedSizeWrapper from "@templates/LimitedSizeWrapper/LimitedSizeWrapper";
import { hexColors } from "@constants/hexColors";
import useIsMobile from "@hooks/useIsMobile";
import Stack from "@design-system/Stack/Stack";
import Button from "@design-system/Button/Button";
import Group from "@design-system/Group/Group";
import { BaseProps } from "@interfaces/BaseProps";
import { TOKEN_ADDRESS, TOKEN_EXPLORER_URL } from "src/config";
import { copyText } from "@utils/copyText";
import Flex from "@design-system/Flex/Flex";

import "./Tokenomics.scss";
import { addTokenToMetamask } from "@utils/metamask";
import { fugaziToken } from "@constants/fugaziToken";
import { sectionIds } from "@constants/sectionIds";

const Base = (props: BaseProps) => {
  const isMobile = useIsMobile("lg");

  return (
    // <Stack alignItems={isMobile ? "flex-start" : "center"} fullWidth {...props}>
    //   <p className="p2 white-black-13">Smart Contract address</p>
    //   <Button as="a" href={SMART_CONTRACT_URL} target="_blank">
    //     <Group colGap={8}>
    //       <h3 className="underline blue-8 semi-bold">Base</h3>
    //       <img src="/images/icons/arrow-top-right-blue.svg" />
    //     </Group>
    //   </Button>
    // </Stack>
    <Group justifyContent="flex-start" alignItems="center" colGap={16}>
      <img src={`/images/logos/base-color.svg`} />
      <Stack rowGap={8} alignItems="flex-start">
        <Stack alignItems="flex-start">
          <p className="p3 blue-15">Token address</p>
          <Group colGap={8}>
            <a
              className={`${isMobile ? "h4" : "h3"} border-bottom blue-8 semi-bold`}
              href={TOKEN_EXPLORER_URL}
              target="_blank"
            >
              basescan.org
            </a>
            <img src="/images/icons/arrow-top-right-blue.svg" />
          </Group>
        </Stack>
        <Button as="button" onClick={() => copyText(TOKEN_ADDRESS)}>
          <Group colGap={4}>
            <div
              style={{ width: "100%", maxWidth: isMobile ? "150px" : "215px", height: "23px" }}
              className="overflow-n"
            >
              <p className="p1 blue-8 underline ellipsis" title="Copy address">
                {TOKEN_ADDRESS}
              </p>
            </div>
            <img src="/images/icons/copy-blue.svg" title="Copy address" />
          </Group>
        </Button>
      </Stack>
    </Group>
  );
};

const AddToken = () => {
  const isMobile = useIsMobile("lg");
  return (
    <Group colGap={16} justifyContent="flex-start" alignItems="center">
      <img src={`/images/logos/metamask-head-big${isMobile ? "-mobile" : ""}.svg`} />
      <Stack alignItems="flex-start">
        <p className="p3 blue-15">Add token to Metamask</p>
        <Button as="button" onClick={() => addTokenToMetamask(fugaziToken)}>
          <Group colGap={8} alignItems="flex-end">
            <h3 className={`${isMobile ? "h4" : "h3"} border-bottom blue-8 semi-bold capitalize-each`}>add token</h3>
            <img src="/images/icons/arrow-top-right-blue.svg" />
          </Group>
        </Button>
      </Stack>
    </Group>
  );
};

const Tokenomics = () => {
  const isMobile = useIsMobile("lg");

  return (
    <FullSizeWrapper
      background={hexColors.yellowish}
      className="tokenomics pt-xs-5 pt-lg-9 pb-xs-5 pb-lg-12"
      id={sectionIds.tokenomics}
    >
      <LimitedSizeWrapper>
        <Stack justifyContent="flex-start" alignItems="center" fullSize>
          <h1 className={`t-xs-l t-lg-c w-xs-100 mb-xs-2 mb-lg-9 text-shadow-5 extra-bold`}>Tokenomics</h1>
          {/* <Base className="d-xs-f d-lg-n mb-xs-4 mb-lg-0" /> */}
          <Group justifyContent="center">
            <img
              src={`/images/graphics/tokenomics${isMobile ? "-mobile" : ""}.png`}
              className={`m-xs-a ${isMobile ? "relative-center-x" : ""}`}
              style={{ marginBottom: isMobile ? "0px" : "-150px", maxWidth: "100%", minWidth: "320px" }}
            />
          </Group>
          <Group fullWidth justifyContent={isMobile ? "flex-start" : "center"}>
            <Flex
              baseRole="group"
              mobileBreakpoint="lg"
              colGap={60}
              rowGap={8}
              justifyContent="flex-start"
              alignItems="center"
            >
              <Base />
              <AddToken />
            </Flex>
          </Group>
        </Stack>
      </LimitedSizeWrapper>
    </FullSizeWrapper>
  );
};

export default Tokenomics;
