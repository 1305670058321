import FullSizeWrapper from "@templates/FullSizeWrapper/FullSizeWrapper";
import LimitedSizeWrapper from "@templates/LimitedSizeWrapper/LimitedSizeWrapper";

import Stack from "@design-system/Stack/Stack";
import Group from "@design-system/Group/Group";

import Button from "@design-system/Button/Button";

import { hexColors } from "@constants/hexColors";
import { globalSocials } from "@constants/globalSocials";
import { LIGHT_PAPER_URL, LOGO_PACK_SRC, OPEN_SEA_URL } from "@constants/constants";

import useIsMobile from "@hooks/useIsMobile";
import { BaseProps } from "@interfaces/BaseProps";

import "./Footer.scss";

interface Props extends BaseProps {
  href: string;
  socialImgPath?: string;
  name: string;
  isDisabled?: boolean;
  groupClassName?: string;
}

const FooterLink = ({ href, name, socialImgPath, isDisabled, groupClassName }: Props) => {
  const isMobile = useIsMobile("lg");
  return (
    <Button as="a" href={href} target="_blank" className={`${isDisabled ? "disabled op-04 none-events" : ""}`}>
      <Group colGap={8} alignItems="flex-end" className={`${groupClassName ? groupClassName : ""}`}>
        {socialImgPath && <img src={socialImgPath} />}
        <h3
          className="white-0 semi-bold border-bottom"
          style={{ minWidth: isMobile ? "unset" : "290px", maxWidth: "min(221px, 100%)" }}
        >
          {name}
        </h3>
        <img src="/images/icons/arrow-top-right-white.svg" />
      </Group>
    </Button>
  );
};

const Footer = () => {
  const isMobile = useIsMobile("xl");
  return (
    <FullSizeWrapper background={hexColors.blue15} className="pt-xs-10 pb-xs-10">
      <LimitedSizeWrapper className="footer-bg">
        <Stack className="footer" fullSize rowGap={200}>
          <Group
            className="top-1 pb-xs-7 flex-wrap"
            colGap={40}
            rowGap={80}
            fullWidth
            justifyContent={`${isMobile ? "flex-start" : "center"}`}
            alignItems="flex-start"
          >
            <Stack>
              <p className="p1 white-0 mb-xs-3 ">FugaziAI logo pack</p>
              <img src="/images/graphics/footer-logo.png" alt="" className="mb-xs-3" style={{ maxWidth: "100%" }} />
              <Button as="a" href={LOGO_PACK_SRC} download>
                <Group colGap={8} alignItems="center">
                  <h3
                    className="white-0 semi-bold border-bottom t-xs-l"
                    style={{ minWidth: isMobile ? "unset" : "320px", maxWidth: "100%" }}
                  >
                    Download logo
                  </h3>
                  <img src="/images/icons/arrow-down.svg" />
                </Group>
              </Button>
            </Stack>
            <Stack>
              <p className="p1 white-0 mb-xs-5 mb-lg-3">Socials</p>
              <Stack rowGap={24}>
                <FooterLink href={globalSocials.x.url} name="Twitter" socialImgPath="/images/footer/x.svg" />
                <FooterLink
                  href={globalSocials.telegram.url}
                  name="Telegram"
                  socialImgPath="/images/footer/telegram.svg"
                />
              </Stack>
            </Stack>
            <Stack>
              <p className="p1 white-0 mb-xs-3">Documentation</p>
              <Stack rowGap={24}>
                <FooterLink href={LIGHT_PAPER_URL} name="Light Paper" />
                <FooterLink href={OPEN_SEA_URL} name="Buy NFT on Opensea" isDisabled={true} groupClassName="w-xs-100" />
              </Stack>
            </Stack>
          </Group>
          <Group
            justifyContent="space-between"
            className="flex-wrap"
            rowGap={24}
            fullWidth
            style={{ minHeight: "60px" }}
          >
            <Group className="address" colGap={24}>
              <Group colGap={16}>
                <p className="p1 white-0 bold">Contact: </p>
                <a
                  className="p1 white-0 underline"
                  href="mailto:support@fugazi-ai.com?subject=Hi%2C%20I'm%20contacting%20from%20FugaziAI%20website"
                >
                  support@fugazi-ai.com
                </a>
              </Group>
            </Group>
            <p className="p1 white-0">&copy; {new Date().getFullYear()} Fugazi. All right reserved.</p>
          </Group>
        </Stack>
      </LimitedSizeWrapper>
    </FullSizeWrapper>
  );
};

export default Footer;
