import { BaseProps } from "@interfaces/BaseProps";
import "./LimitedSizeWrapper.scss";

interface Props extends BaseProps {
  maxSize?: number;
  background?: string;
}

const LimitedSizeWrapper = ({ children, maxSize, background, ...rest }: Props) => {
  return (
    <section style={{ maxWidth: maxSize, background }} {...rest} className={`limited-size-wrapper ${rest.className}`}>
      {children}
    </section>
  );
};

export default LimitedSizeWrapper;
