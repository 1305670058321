export interface TradeToken {
  address: string;
  symbol: string;
  decimals: number;
  image: string;
}

export const addTokenToMetamask = async (token: TradeToken) => {
  const ethereum = (window as any).ethereum;
  if (!ethereum) {
    delegateToInstallMetamask();
    return;
  }

  try {
    await ethereum.request({
      method: "wallet_watchAsset",
      params: {
        type: "ERC20",
        options: {
          ...token
        }
      }
    });
  } catch (error) {
    console.log(error);
  }
};

const delegateToInstallMetamask = () => {
  const GO_TO_METAMASK_URL = `https://metamask.app.link/dapp/${window.location.host}`;
  const link = document.createElement("a");
  link.href = GO_TO_METAMASK_URL;
  link.target = "_blank";
  link.click();
};
