import { BaseProps } from "@interfaces/BaseProps";

import "./StepsButton.scss";

interface Props extends BaseProps<HTMLButtonElement> {
  disabled?: boolean;
}

const StepsButton = ({ children, disabled, ...rest }: Props) => {
  return (
    <button className="steps-button" {...rest} disabled={disabled}>
      {children}
    </button>
  );
};

export default StepsButton;
